require('./bootstrap');
// import Typed from 'typed.js';
import Vivus from 'vivus';

document.addEventListener('DOMContentLoaded', (event) => {

    // const typed = new Typed('#jumbo-type', {
    //     strings: [
    //       'Scale Your Real Estate Business',
    //       'Change your life',
    //       'Reach your full potential',
    //       'Live your dream life',
    //       'Embrace new opportunities',
    //       'Unlock your true potential',
    //       'Turn your dreams into reality',
    //       'Achieve greatness',
    //       'Work From Home',
    //       'Break free from limitations',
    //       'Create the life you love'
    //     ],
    //     typeSpeed: 75,
    //     loop: true,
    //     loopCount: 100,
    //     showCursor: false,
    // });

    new Vivus('logo-vivus', {duration: 300});

    document.getElementById('calendlyButton').addEventListener('click', function() {
        Calendly.initPopupWidget({url: 'https://calendly.com/brandon-labonty-exp/30min'});
        return false;
    });

    document.getElementById('calendlyButton2').addEventListener('click', function() {
        Calendly.initPopupWidget({url: 'https://calendly.com/brandon-labonty-exp/30min'});
        return false;
    });

})
